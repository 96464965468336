import { useHomepageHeroSlider } from '../../../lib/hooks/use-homepage-hero-slider'
import { Arrows } from './Arrows'
import { Indicators } from './Indicators'
import { NavigationBackground } from './NavigationBackground'
import HomepageHeroSliderImage from '@components/HomepageHeroSliderImage'
import { cn } from '@lib/cn'
import { HomepageHeroItem } from '@lib/kontent/models/HomepageHeroItem'
import { AnimatePresence } from 'framer-motion'
import React, { RefObject } from 'react'
import { useSwipeable } from 'react-swipeable'
import { BannerContentDesktop } from '../bannerContent/BannerContentDesktop'
import { BannerContentMobile } from '../bannerContent/BannerContentMobile'

type Props = {
  items: HomepageHeroItem[]
  ref: RefObject<HTMLElement>
}

type Style = 'light' | 'dark'

const Hero = React.forwardRef<HTMLElement, Props>(({ items }) => {
  const slideLength = items.length
  const { currentIndex, setCurrentIndex, nextSlide, prevSlide } = useHomepageHeroSlider(slideLength)
  const activeSlide = items[currentIndex]
  const nextActiveSlide = slideLength > 1 ? items[(currentIndex + 1) % slideLength] : activeSlide

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
  })
  const style = (activeSlide?.style?.value[0].codename as Style) ?? 'light'
  const hideContentOnDesktop = activeSlide?.apply_brand_style.value[0]
    ? activeSlide.apply_brand_style.value[0].codename === 'chanel'
    : false

  return (
    <section className="relative">
      <AnimatePresence exitBeforeEnter>
        <div
          key={currentIndex}
          className={cn(
            'relative mx-auto w-full max-w-[2560px] overflow-hidden',
            'before:content before:block before:pt-[122%] sm:before:pt-[60%] md:before:pt-[70%] lg:before:pt-[55%] xl:before:pt-[47%] xxl:before:pt-[725px]',
          )}
          {...handlers}
        >
          <div className="pointer-events-none absolute left-0 top-0 z-[2] h-full w-full opacity-0" />
          <div className="pointer-events-none absolute left-0 top-0 z-[2] h-full w-full opacity-0" />
          <HomepageHeroSliderImage {...activeSlide} slideToPreload={nextActiveSlide} />
          <NavigationBackground variant={style}>
            {slideLength > 1 && (
              <>
                <Indicators
                  slideLength={slideLength}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                />
                <Arrows handleClickNext={nextSlide} handleClickPrev={prevSlide} />
              </>
            )}
          </NavigationBackground>
        </div>
        {!hideContentOnDesktop && <BannerContentDesktop item={activeSlide} />}
        <BannerContentMobile item={activeSlide} />
      </AnimatePresence>
    </section>
  )
})

export default Hero
